var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:[
    { 'nav-open': _vm.$sidebar.showSidebar },
    { rtl: _vm.$route.meta.rtlActive } ]},[_c('notifications'),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('user-menu'),_c('mobile-menu'),_c('template',{slot:"links"},[(_vm.$route.meta.rtlActive)?_c('sidebar-item',{attrs:{"link":{
          name: 'لوحة القيادةة',
          icon: 'dashboard',
          path: '/dashboard',
        }}}):_c('sidebar-item',{attrs:{"link":{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }}}),_c('sidebar-item',{attrs:{"opened":"","link":{ name: 'Examples (API)', image: _vm.image }}},[_c('sidebar-item',{attrs:{"link":{ name: 'User Profile', path: '/examples/user-profile' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'User Management',
            path: '/examples/user-management/list-users',
          }}})],1),(_vm.$route.meta.rtlActive)?_c('sidebar-item',{attrs:{"link":{
          name: 'الجداول',
          icon: 'content_paste',
          path: '/components/table',
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Table Lists',
          icon: 'content_paste',
          path: '/components/table',
        }}}),(_vm.$route.meta.rtlActive)?_c('sidebar-item',{attrs:{"link":{
          name: 'طباعة',
          icon: 'library_books',
          path: '/components/typography',
        }}}):_c('sidebar-item',{attrs:{"link":{
          name: 'Typography',
          icon: 'library_books',
          path: '/components/typography',
        }}}),(_vm.$route.meta.rtlActive)?_c('sidebar-item',{attrs:{"link":{
          name: 'الرموز',
          icon: 'bubble_chart',
          path: '/components/icons',
        }}}):_c('sidebar-item',{attrs:{"link":{
          name: 'Icons',
          icon: 'bubble_chart',
          path: '/components/icons',
        }}}),(_vm.$route.meta.rtlActive)?_c('sidebar-item',{attrs:{"link":{
          name: 'خرائط جوجل',
          icon: 'place',
          path: '/components/maps',
        }}}):_c('sidebar-item',{attrs:{"link":{ name: 'Maps', icon: 'place', path: '/components/maps' }}}),(_vm.$route.meta.rtlActive)?_c('sidebar-item',{attrs:{"link":{
          name: 'إخطارات',
          icon: 'notifications',
          path: '/components/notifications',
        }}}):_c('sidebar-item',{attrs:{"link":{
          name: 'Notifications',
          icon: 'notifications',
          path: '/components/notifications',
        }}}),(_vm.$route.meta.rtlActive)?_c('sidebar-item',{attrs:{"link":{ name: 'دعم رتل', icon: 'language', path: '/components/rtl' }}}):_c('sidebar-item',{attrs:{"link":{
          name: 'RTL Support',
          icon: 'language',
          path: '/components/rtl',
        }}})],1),_c('div',{staticStyle:{"width":"100%","position":"absolute","bottom":"0","padding":"16px"}},[(_vm.sidebarMini)?_c('md-button',{staticClass:"md-button md-danger md-block",attrs:{"href":"https://www.creative-tim.com/product/vue-material-dashboard-laravel-pro-bs4","target":"_blanck"}},[_c('i',{staticClass:"fas fa-download",staticStyle:{"margin-right":"4px"}}),_vm._v("Upgrade to PRO ")]):_vm._e()],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('fixed-plugin',{attrs:{"color":_vm.sidebarBackground,"colorBg":_vm.sidebarBackgroundColor,"sidebarMini":_vm.sidebarMini,"sidebarImg":_vm.sidebarImg,"image":_vm.sidebarBackgroundImage},on:{"update:color":function($event){_vm.sidebarBackground=$event},"update:colorBg":function($event){_vm.sidebarBackgroundColor=$event},"update:color-bg":function($event){_vm.sidebarBackgroundColor=$event},"update:sidebarMini":function($event){_vm.sidebarMini=$event},"update:sidebar-mini":function($event){_vm.sidebarMini=$event},"update:sidebarImg":function($event){_vm.sidebarImg=$event},"update:sidebar-img":function($event){_vm.sidebarImg=$event},"update:image":function($event){_vm.sidebarBackgroundImage=$event}}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent }},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }